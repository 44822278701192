@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600&family=Quicksand:wght@300;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 1150px) {
    .errorSVG svg {
        width: 300px !important;
        height: auto !important;
    }
}

@media (max-width: 585px) {
    .errorSVG svg {
        width: 200px !important;
        height: auto !important;
    }

    .mobileHidden {
        display: none;
    }
}
