#SliderRoot {
  color: #A4C9D6 !important;

  .MuiSlider-rail,
  .MuiSlider-track {
    height: 5px !important;
    border-radius: 15px !important;
  }

  .MuiSlider-rail {
    background-color: #D5EAF1 !important;
    opacity: 1 !important;
  }

  .chooseDays {

    .MuiSlider-rail::after {
      content: "";
      position: absolute;
      height: 5px;
      width: 50%;
      right: 0;
      background-color: #FDC93A;
      transition: all .2s linear;
      opacity: 1;
      //z-index: 11;

    }

    span[data-index="4"],
    span[data-index="5"],
    span[data-index="6"],
    span[data-index="7"],
    span[data-index="8"] {
      background-color: #FDC93A !important;
    }
  }

  .MuiSlider-track {
    background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
    opacity: .8 !important;
    border: none !important;
  }

  .MuiSlider-mark {
    height: 20px !important;
    background-color: #C3DCE4 !important;

    @media (max-width: 585px) {
      height: 15px !important;
    }

  }

  .MuiSlider-thumb {
    box-shadow: none;
  }

  .MuiSlider-thumb::after {
    width: 48px;
    height: 48px;
    display: flex;
    position: absolute;
    top: -50%;
    left: -50%;
    transform: rotate(-225deg) !important;
    align-items: center;
    border-radius: 50% 50% 50% 0 !important;
    justify-content: center;
    background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;

    @media (max-width: 585px) {
      width: 35px;
      height: 35px;
      top: -5px;
    }

  }

  .MuiSlider-thumb::before {
    content: "";
    background: url("../img/markersPoints.png") no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    left: 4.5px;
    top: 2px;
    display: block;
    position: absolute;
    z-index: 10;
    box-shadow: none !important;
    padding: 5px !important;

    @media (max-width: 585px) {
      height: 16px;
      width: 16px;
      left: 1.5px;
      top: 2px;
    }
  }

  .MuiSlider-markActive {
    background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
  }

  #input-slider {
    color: #A4C9D6 !important;
    font-weight: 600;
    font-family: Quicksand;
    margin-top: 10px;
  }


}